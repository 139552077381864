import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import {deviceSizes} from '../../../../config/devices'

export const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    margin-top: 64px;
    width: ${({ open }) => (open ? '240px' : '60px')};
    display: flex;
    overflow-x: hidden;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    /* position: inherit; */
  }

  @media (max-width: ${deviceSizes.sm}) {
    .MuiPaper-root {
      width: ${({ open }) => (open ? '100%' : '0')};
    }
  }
`;

export const MenuButton = styled(IconButton)`
  width: 60px;
`;
