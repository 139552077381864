import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import { StyledDrawer } from './style';
// import { organisationList } from './Lists';
import Lists from './Lists';
import { USER_ROLES } from '../../../../config/constants';

const Sidebar = ({ isOpen, userInfo }) => (
    <StyledDrawer variant="permanent" open={isOpen}>
      {userInfo && userInfo.role.name !== USER_ROLES.USER && <Lists.organisationList />}
      {userInfo && userInfo.role && (<Lists.GeneralList role={userInfo.role.name} hasReviews={userInfo.has_reviews} />)}
      <Divider />
    </StyledDrawer>
  );

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  userInfo: PropTypes.object.isRequired,
};

Sidebar.defaultProps = {
  isOpen: false,
};

export default Sidebar;
