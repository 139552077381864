/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded';
// import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RateReviewIcon from '@mui/icons-material/RateReview';
// import { useRouter } from 'next/router';
import { AutoGraph, Chat } from '@mui/icons-material';
// import { Badge } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import { useSpaces } from '@app/contexts/SpacesContext';
import { useUser } from '@app/helpers/hooks';
import SidebarItem from './SidebarItem';
import { useTranslation } from '../../../../config/i18n';


// const { getRouteName } = require('../../../../server/routes');

const Lists = ({ children }) => <List>{children}</List>;

Lists.propTypes = {
  children: PropTypes.node,
};

Lists.defaultProps = {
  children: <></>,
};

export const organisationList = () => {
  const { t } = useTranslation('common');

  // const activeRouteName = routes.map

  return (
    <List style={{padding:'0'}}>
      <SidebarItem
        text={t('my_opps')}
        icon={<DashboardRoundedIcon />}
        link="my_opportunities"
      />
      <SidebarItem
        text={t('organisation_plural')}
        icon={<BusinessRoundedIcon />}
        link="my_organisations"
      />
      <SidebarItem
        text={t('Impact')}
        icon={<AutoGraph />}
        link="insight_dashboard"
      />
      

      {/* <SidebarItem
        text={t('rev_invitations')}
        icon={<PlaylistAddCheckRoundedIcon />}
        link="my_organisations"
      /> */}
    </List>
  );
};

export const GeneralList = ({ role, hasReviews }) => {
  const { t } = useTranslation('common');
  const {globalUnreadCount, setGlobalUnreadCount} = useSpaces();

  const { user } = useUser();
  const isProduction = process.env.NODE_ENV === 'production';
  const allowedEmails = ['jmi@mubazar.com', 'matt@jmi.net'];

  return (
    <List style={{padding:'0'}}>
      <SidebarItem
        text={t('explore_opportunities')}
        icon={<LibraryMusicRoundedIcon />}
        link="opportunity_search"
      />
      <SidebarItem
        text={t('my_applications')}
        icon={<AssignmentIcon />}
        link="my_applications"
      />
      {(!isProduction || allowedEmails.includes(user?.email)) ? (
        <SidebarItem
          text={t('spaces')}
          icon={<GroupsIcon badgeContent={globalUnreadCount}><Chat /></GroupsIcon>}
          link="spaces"
        />
      ) : null}
      { hasReviews && <SidebarItem
        // text={t('my_applications')}
        text='Reviewing'
        icon={<RateReviewIcon />}
        link="review_opportunities"
      />}
     {/* {role === USER_ROLES.USER && (
        <SidebarItem
          text={t('upgrade_account')}
          icon={<ArrowUpwardIcon />}
          link="account_upgrade"
        />
     )} */}
    </List>
  );
};

GeneralList.propTypes = {
  role: PropTypes.string.isRequired,
  hasReviews: PropTypes.bool
};

GeneralList.defaultProps = {
  hasReviews: false
};

Lists.organisationList = organisationList;
Lists.GeneralList = GeneralList;

export default Lists;
