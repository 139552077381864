import React from 'react';
import PropTypes from 'prop-types';
import StyledSkeleton from './style';

const Skeleton = ({ margin, width, ...props }) => (
  <StyledSkeleton 
    width={width}
    margin={margin}
    {...props} 
  />
);

Skeleton.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.string,

};

Skeleton.defaultProps = {
  margin: null,
  width: '100%'
};

export default Skeleton;
