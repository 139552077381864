import React from 'react'
import PropTypes from 'prop-types'
import { StyledPaper } from './style'


const Paper = ({rounded, ...props}) => (
    <StyledPaper 
        rounded={rounded}
        {...props} 
    />
);

Paper.propTypes = {
    rounded: PropTypes.bool,
}

Paper.defaultProps = {
    rounded: false,
};
  
export default Paper
