import styled from 'styled-components';
import { Container as MContainer, Grid } from '@mui/material';
import { deviceSizes } from '../../../config/devices'

import Paper from '../../atoms/Paper';

export const BoxContianer = styled(MContainer)`
  padding-top: 90px;
  padding-bottom: 60px;

  @media only screen and (min-width: ${deviceSizes.mobileS}) and (max-width: ${deviceSizes.mobileM}) {
    > .MuiContainer-root {
      padding-left: 0;
      padding-right: 0;
    }
  
  }
`;

export const ImageContainer = styled(Grid)`
&& {
  margin-top: -2rem;
  margin-bottom: -2rem;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-image: url(${({ src }) => src});
}


`;

export const StyledPaper = styled(Paper)`
  && {
    padding-right: 0;
    display: flex;
    @media (max-width: ${deviceSizes.mobileL}) {
      padding-left: 0;
    }
  }
`;
