import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Grid from '@mui/material/Grid';
import { useQuery } from '@apollo/client';

import VerficationAlert from '@molecules/common/VerficationAlert';
import Header from '@organisms/common/Header';
import Sidebar from '@organisms/common/Sidebar';

import { GET_SIDEBAR_STATUS, USER_QUERY } from '@app/apollo/queries';

import { BoxContianer, LayoutContainer, ContentContainer, VerficationContainer } from './style';


const HeaderWithSidebar = ({ children, title, contentPadding }) => {
  const { data: { sidebarOpen } = { sidebarOpen: false }, client } = useQuery(GET_SIDEBAR_STATUS);
  const { data, loading } = useQuery(USER_QUERY);

  const user = data?.me || null;

  const toggleSidebar = () => {
    client.writeQuery({
      query: GET_SIDEBAR_STATUS,
      data: { sidebarOpen: !sidebarOpen },
    });
  };
  
  // console.log("user", user)
  return (
    <>
      <Head>
        <title>{`Mubazar ${title}`}</title>
      </Head>
      <LayoutContainer>
        <Header sidebar={!!user} onClick={toggleSidebar} />
        <>
          <ContentContainer>
            {user && <Sidebar isOpen={sidebarOpen} userInfo={user} />}
            <BoxContianer contentPadding={contentPadding} isOpen={sidebarOpen} hasSidebar={!!user}>
              <LayoutContainer>
                {!loading && user && !user.is_verified ? (
                  <VerficationContainer>
                    <VerficationAlert />
                  </VerficationContainer>
                )
                  : <VerficationContainer />
                }
                <Grid item xs={12} sm={12}>
                  {children}
                </Grid>
              </LayoutContainer>
            </BoxContianer>
          </ContentContainer>
        </>

        {/* <Footer /> */}
      </LayoutContainer>
    </>
  );
};

HeaderWithSidebar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  contentPadding: PropTypes.number,
};

HeaderWithSidebar.defaultProps = {
  title: '',
  contentPadding: 2,
  children: undefined
};

export default HeaderWithSidebar;
