import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from '@mui/material';
import Head from 'next/head';
import Hidden from '@mui/material/Hidden';
import { BoxContianer, ImageContainer, StyledPaper } from './style';
import Header from '../../organisms/common/Header';
import Footer from '../../organisms/common/Footer';
// import Paper from '../../atoms/Paper';
// import Img from '../../atoms/Image';
// import landingImage from '../../../static/images/landing.jpg';

const TwoColumns = ({ children, title, img }) => (
  <>
    <Head>
      <title>{`Mubazar ${title}`}</title>
    </Head>
    <Header />
    <BoxContianer>
      <Container maxWidth="lg">
        <StyledPaper>
          <Grid container>
            <Grid item xs={12} lg={6} sm={12} md={6}>
              {children}
            </Grid>
            <Hidden only={['sm', 'xs']}>
              <ImageContainer item src={img} lg={6} md={6} xs={12} sm={6}>
                {/* <Img src="/images/landing.jpg" /> */}
              </ImageContainer>
            </Hidden>
          </Grid>
        </StyledPaper>
      </Container>
    </BoxContianer>
    <Footer />
  </>
);


TwoColumns.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  img: PropTypes.string,
};

TwoColumns.defaultProps = {
  title: '',
  img: "/images/landing.jpg"
};

export default TwoColumns;
