import React from 'react';
// import PropTypes from 'prop-types'
import { AlertTitle, Alert as MuiAlert } from '@mui/material';

const Alert = ({ ...props }) => {
  return <MuiAlert {...props} />;
};

Alert.propTypes = {};

Alert.AlertTitle = AlertTitle;

export default Alert;
