import React,{useState} from 'react';
// import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useMutation } from '@apollo/client';

import Button from '@atoms/Button';
import Snackbar from '@molecules/Snackbar';

import { useTranslation } from '@app/config/i18n';
import { REQUEST_VERFICATION } from '@app/apollo/mutations';
import { Alert } from './style';

const VerficationAlert = () => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const [
    requestVerfication,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(REQUEST_VERFICATION, {
    onCompleted: (data) => {
      if (data) {
        setOpen(true);
      }
    }
  });

  const requestVerficationHandler = () => {
    requestVerfication();
  };

  return (
    <>
      <Snackbar type="error" open={!!mutationError} message={<div>{t('sorry.error')}</div>} />
      <Snackbar type="loading" open={mutationLoading} message={<div>{t('loading')} </div>} />
      <Snackbar type="success" open={open} onClose={() => setOpen(false)}  message={<div>{t('success')}</div>} />

      <Alert severity="warning">
        <Alert.AlertTitle>
        {t('alert.type.warning')}: {t('alert.not_verified.title')}
        </Alert.AlertTitle>
        <Grid container direction="row" alignItems="baseline">
          {t('alert.not_verified.body')} 
          <Button variant="text" onClick={requestVerficationHandler} disabled={mutationLoading}>
            {' '}
            {t('alert.not_verified.resend')}
          </Button>
        </Grid>
      </Alert>
    </>
  );
};

VerficationAlert.propTypes = {};

export default VerficationAlert;
