/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ItemIcon, ItemText, Item, StyledLink } from './style';
import { GET_SIDEBAR_STATUS } from '../../../../../apollo/queries';

const SidebarItem = ({ text, icon, link }) => {
const {  client } = useQuery(GET_SIDEBAR_STATUS);
const toggleSidebar = () => {
  client.writeQuery({
    query: GET_SIDEBAR_STATUS,
    data: { sidebarOpen: false },
  });
};
  return (
    <StyledLink href={link} onClick={toggleSidebar}>
      <Item button key={text}>
        <ItemIcon>{icon}</ItemIcon>
        <ItemText primary={text} />
      </Item>
    </StyledLink>
  );
};

SidebarItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};


export default SidebarItem;
