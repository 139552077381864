import styled from 'styled-components';
import { devices, deviceSizes } from '../../../config/devices';


export const BoxContianer = styled.div`
  && {
    min-height: 80vh;
    margin-right: 0;
    margin-left: 0;
    display: flex;
    ${({ contentPadding }) =>
      `padding: ${contentPadding}rem ${contentPadding}rem;`}
    

    transition: margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    background-color: #fafafa;
    
    ${({ hasSidebar, isOpen }) =>
      hasSidebar
        ? `
     margin-left: ${isOpen ? `240px` : `60px`};
    //  width: ${isOpen ? `calc(100% - 240px)` : `calc(100% - 60px)`};
    width: 100%;

    `
        : `
    width: 100%;
    margin-left:0;
    
    `}
    

  @media ${devices.sm} {
      padding: 4.5rem 0 5rem 0;

  }

  @media ${devices.xs} {
      padding: 3.6rem 0 5rem 0;
      margin-left: 0;
  }
  }
`;

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const VerficationContainer = styled.div`
  margin-top: 64px;

  @media ${devices.sm} {
    margin-top: 0;
  }
  @media only screen and (min-width: ${deviceSizes.sm}) and (max-width: ${deviceSizes.md}) {
    margin-top: -8px;
  }
`;
